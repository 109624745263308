<template>
  <b-container class="mb-7">
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>

    <h1>Stations</h1>
    <CreateStationModal />

    <b-row class="container-search">
      <b-col sm="4">
        <b-form-input v-model="form.input" placeholder="Enter search" debounce="300"></b-form-input>
      </b-col>
    </b-row>
    <b-table
      show-empty
      id="table"
      striped
      sort-icon-left
      class="station-table"
      :fields="table.fields"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      <template v-slot:cell(edit)="row">
        <span v-b-modal="row.item.id"><em class="far fa-edit" /></span>
        <EditStationModal :modalId="row.item.id" :station="row.item" />
      </template>

      <template v-slot:cell(showDetails)="row">
        <b-button size="sm" @click="row.toggleDetails" variant="outline-secondary" class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
        </b-button>
      </template>

      <template v-slot:row-details="row">
        <StationDevices :station="row.item" @error="handleError" />
      </template>

      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>

    <b-pagination
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      pills
      aria-controls="table"
      align="center"
      first-text="First"
      prev-text="Prev"
      next-text="Next"
      last-text="Last"
    />
  </b-container>
</template>

<script>
import CreateStationModal from '@/components/CreateStationModal';
import EditStationModal from '@/components/EditStationModal';
import StationDevices from '@/components/StationDevices';
import StationService from '@/lib/station-service';

export default {
  name: 'Stations',
  watch: {
    'form.input': function () {
      this.refreshTable();
    },
    'form.filter': function () {
      this.refreshTable();
    },
    'table.sortBy': function () {
      this.refreshTable();
    }
  },
  components: {
    CreateStationModal,
    EditStationModal,
    StationDevices
  },
  data() {
    return {
      alert: {
        text: '',
        model: false
      },
      form: {
        input: '',
        filter: [true]
      },
      table: {
        isBusy: false,
        fields: [
          { key: 'name', sortable: true },
          { key: 'description', sortable: true },
          { key: 'deviceCount', label: 'Devices', sortable: true },
          { key: 'devices', thClass: 'd-none', tdClass: 'd-none' },
          { key: 'edit' },
          { key: 'showDetails' }
        ],
        rows: 0,
        currentPage: 1,
        perPage: 10,
        sortBy: 'id',
        sortDesc: false
      }
    };
  },
  computed: {
    sortDir() {
      return this.table.sortDesc === true ? 'desc' : 'asc';
    }
  },
  methods: {
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
    },
    items() {
      const params = {
        search: this.form.input.length > 0 ? this.form.input : undefined,
        page: this.table.currentPage - 1,
        pageSize: 10,
        sortBy: this.table.sortBy || undefined,
        sortDir: this.sortDir
      };
      return StationService.listStations(params)
        .then((result) => {
          this.table.rows = result.pagination.total;
          return result.data.map((station) => ({ ...station, deviceCount: station.devices.length }));
        })
        .catch(() => {
          this.handleError('Failed to load station list');
          return [];
        });
    },
    handleError(text) {
      this.alert.text = text;
      this.alert.model = true;
    }
  }
};
</script>

<style scoped>
.container-search {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.container {
  text-align: left;
  margin-left: 115px;
}
.container-add-device {
  padding-bottom: 1rem;
}
.container-search {
  padding-bottom: 1rem;
}
.station-table {
  margin-top: 2rem;
}
</style>
